import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { Typography } from 'antd';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { round, displayAmount } from 'helpers/amount';
import { DATE_FORMAT, ACCOUNT_TYPES_MAP, FIRMA_AGREEMENT, PRIVATE_CUSOMTER_AGREEMENT, env } from 'utils/constants';
import request from 'helpers/request';
import { getLanguageSchema } from 'dashboard-commons/languages';
import Card from 'components/UI/Card';
import DocsAgreementModal from './AgreementModal';

let ListItem = props => {
	let { reservation, languageSchema, fetchData, cancelReservation, user } = props;

	let agreementDocumentLink = user?.accountType === ACCOUNT_TYPES_MAP.individual ?
		PRIVATE_CUSOMTER_AGREEMENT :
		FIRMA_AGREEMENT;

	let [isLoading, setIsLoading] = useState(false);
	let [isAgreementModalOpened, setIsAgreementModalOpened] = useState(false);

	let toggleCarLimitModal = useCallback(() => setIsAgreementModalOpened(!isAgreementModalOpened), [isAgreementModalOpened]);

	let initialPayment = round(
		reservation.initialPureAmount +
		reservation.insuranceMonthRate +
		reservation.vatAmount +
		reservation.insuranceVatAmount +
		reservation.pureMonthRate
	);

	let vatAmount = round(reservation.pureMonthRate * reservation.vatRate / 100);
	let regularPayment = round(reservation.pureMonthRate + reservation.insuranceMonthRate + vatAmount + reservation.insuranceVatAmount);
	let pureAmountWithVat = round(reservation.pureMonthRate + vatAmount);
	let specialMonthPayment = round(pureAmountWithVat - (pureAmountWithVat * reservation.specialMonthDiscount / 100) + reservation.insuranceMonthRate + reservation.insuranceVatAmount);
	let rentingPeriod = reservation.isWeeklyPaymentChosen ? 'weeks' : 'months';

	let rentPeriodsNumber = reservation.endRentDate ?
		moment(reservation.endRentDate, 'MM/DD/YYYY').diff(moment(reservation.startRentDate, 'MM/DD/YYYY'), rentingPeriod) :
		1;

	let expireDate = moment(reservation.createdAt).add(3, 'days').format(DATE_FORMAT);
	let isReservationExpired = moment(new Date()).isAfter(moment(expireDate, DATE_FORMAT));

	let renderExpireDate = useCallback(() => {
		if (isReservationExpired) {
			return <Typography.Text type="danger"><b>{languageSchema.BoxBooking.expired}</b></Typography.Text>;
		}

		return (
			<>
				<Typography.Text type="warning">
					<b>{languageSchema.BoxBooking.expires}</b>:
				</Typography.Text> <br />
				{expireDate}
			</>
		);
	}, [reservation]);

	let handleCancelReservation = (reservation) => async () => {
		toastr.confirm(props.languageSchema.BoxBooking.areYouSureToCancelReservation, {
			onOk: async () => {
				try {
					setIsLoading(true);
					await cancelReservation(reservation.ReservationID);


					if (env === 'production') {
						ym(89808973,'reachGoal', 'reservation_cancelled'); // eslint-disable-line
					}

					toastr.success(
						props.languageSchema.Etc.success,
					);

					setIsLoading(false);
					setTimeout(() => fetchData(), 1000);
				} catch (error) {
					setIsLoading(false);

					toastr.error(
						'',
						languageSchema.errors[error.errorCode || 'unrecognized']
					);
				}
			}
		});
	};

	let enterReservationIntoContract = async() => {
		try {
			toggleCarLimitModal();
			setIsLoading(true);

			await request('POST', 'contracts', { reservationId: reservation.ReservationID });

			toastr.success(
				props.languageSchema.Etc.success,
			);

			setIsLoading(false);
			setTimeout(() => fetchData(), 1000);
		} catch (error) {
			setIsLoading(false);

			toastr.error(
				'',
				languageSchema.errors[error.errorCode || 'unrecognized']
			);
		}
	};

	return (
		<Card grid={6}>
			<div className="card-text d-flex flex-wrap justify-content-between">
				<div className="text-center my-2" style={{ fontSize: '18px' }}>
					<b>{languageSchema.BoxBooking.reservationId}</b>: <br />
					{reservation.UnitNumber}
				</div>
				<div className="text-right my-2" style={{ fontSize: '18px' }}>
					{renderExpireDate()}
				</div>
			</div>
			<div className="d-flex flex-wrap justify-content-between">
				<div>
					<b>{languageSchema.BoxBooking.stock}</b>: <br />
					{reservation.stock.cityId.city}, {reservation.stock.address}
				</div>
				<div className="text-right my-2">
					<b>{languageSchema.BoxBooking.box}</b>: <br />
					{reservation.UnitNumber} ({reservation.PhysicalSize}m<sup>2</sup>)
				</div>
			</div>

			<div className="d-flex flex-wrap justify-content-between w-100">
				<div className="my-2">
					<b>{languageSchema.BoxBooking.startRentDate}</b>: <br />
					{moment(reservation.startRentDate, 'MM/DD/YYYY').format(DATE_FORMAT)}
				</div>
				<div style={{ maxWidth: '190px' }} className="my-2 text-right">
					<b>{languageSchema.BoxBooking.endRentDate}</b>: <br />
					{
						reservation.endRentDate ?
							moment(reservation.endRentDate, 'MM/DD/YYYY').format(DATE_FORMAT):
							languageSchema.BoxBooking.reservationWithoutDepartureDateDescription
					}

				</div>
			</div>

			<div className="mb-2 mt-2 flex-center vertical-centered flex-vertical">
				<b>{languageSchema.BoxBooking.initialPayment}:</b>
				<span style={{ fontSize: '26px' }}>
					{displayAmount(initialPayment, reservation.stock.currency)}
				</span>
			</div>

			<div className="d-flex flex-wrap justify-content-between vertical-centered">
				{
					1 < rentPeriodsNumber &&
					<div className="my-2" style={{ maxWidth: '220px' }}>
						<b>{
							reservation.isWeeklyPaymentChosen ?
								languageSchema.BoxBooking.paymentFromSecondWeek :
								languageSchema.BoxBooking.paymentFromSecondMonth
						}</b>: <br />
						<span style={{ fontSize: '20px' }}>
							{displayAmount(regularPayment, reservation.stock.currency)}
						</span>
					</div>
				}

				{
					Boolean(reservation.specialMonth) && Boolean(reservation.specialMonthDiscount) &&
					<div className="text-right my-2" style={{ maxWidth: '220px' }}>
						<b>{languageSchema.BoxBooking.specialMonthPaymentDescription(reservation.specialMonth, reservation.specialMonthDiscount)}</b>: <br />
						<span style={{ fontSize: '20px' }}>
							{displayAmount(specialMonthPayment, reservation.stock.currency)}
						</span>
					</div>
				}

			</div>
			<div className="d-flex justify-content-between vertical-centered">
				<div>
					<button
						className="btn btn-md btn-primary"
						onClick={handleCancelReservation(reservation)}
						disabled={isLoading}
					>
						{
							isLoading ?
								languageSchema.Etc.loading :
								languageSchema.BoxBooking.cancel
						}
					</button>
				</div>

				{
					reservation.paidUntil && !isReservationExpired &&
					<div>
						<button
							style={{ marginLeft: '5px' }}
							className="btn btn-md btn-success"
							onClick={() => setIsAgreementModalOpened(true)}
							disabled={isLoading}
						>
							{
								isLoading ?
									languageSchema.Etc.loading :
									languageSchema.BoxBooking.enterIntoContract
							}
						</button>
					</div>
				}
			</div>

			{
				isAgreementModalOpened &&
				<DocsAgreementModal
					isModalOpen={isAgreementModalOpened}
					handleSubmit={enterReservationIntoContract}
					handleCancel={() => setIsAgreementModalOpened(false)}
					languageSchema={languageSchema}
					docLink={agreementDocumentLink}
					rentalData={{
						selectedStock: reservation.stock,
						arrivalDate: reservation.startRentDate,
						departureDate: reservation.endRentDate,
						boxNumber: reservation.UnitNumber,
						physicalSize: reservation.PhysicalSize,
						insuranceMonthRate: reservation.insuranceMonthRate,
						selectedInsuranceAmount: reservation.insuranceAmount,
						user,
						pureMonthRate: reservation.pureMonthRate,
						vatAmount: reservation.vatAmount,
						isWeeklyPaymentChosen: reservation.isWeeklyPaymentChosen
					}}
				/>
			}
		</Card>
	);
};


let mapStateToProps = ({ common }) => {
	return {
		language: common.language,
		languageSchema: getLanguageSchema(common.language),
	};
};

export default connect(mapStateToProps)(ListItem);
