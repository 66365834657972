import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import Checkbox from 'react-simple-checkbox';

import request from 'helpers/request';
import { round } from 'helpers/amount';
import { REGULAMIN_URL, INSURANCE_CONDITIONS_URL } from 'utils/constants';

function DocsAgreementModal(props) {
	const {
		isModalOpen,
		handleSubmit,
		handleCancel,
		languageSchema,
		docLink,
		rentalData
	} = props;

	let {
		selectedStock,
		arrivalDate,
		departureDate,
		boxNumber,
		physicalSize,
		insuranceMonthRate,
		insuranceVatAmount,
		insuranceWeekRate,
		selectedInsuranceAmount,
		user,
		pureMonthRate,
		vatAmount,
		isWeeklyPaymentChosen
	} = rentalData;


	const [areDocsConfirmed, toggleDocsAgreed] = useState(false);
	const [previewPDF, setPreviewPDF] = useState(null);
	const [regulaminHTML, setRegulaminHTML] = useState(null);
	const [insuranceConditionsHTML, setInsuranceConditionsHTML] = useState(null);

	useEffect(() => {
		if(!_.isNull(rentalData.arrivalDate) && isModalOpen) {
			let fetchPreviewPDF = async () => {

				let previewPDFData = {
					boxLocationCity: selectedStock.cityId.city,
					boxLocationAddress: selectedStock.address,
					arrivalDate: moment(arrivalDate).format('DD.MM.YYYY'),
					departureDate: departureDate ? moment(departureDate).format('DD.MM.YYYY') : null,
					boxNumber,
					boxSquare: physicalSize,
					insuranceMonthly: round(insuranceMonthRate + insuranceVatAmount),
					insuranceWeekly: insuranceWeekRate,
					insuranceCoverageAmount: selectedInsuranceAmount,
					stockId_sm: selectedStock.stockId_sm,
					userId: user._id,
					amount: pureMonthRate + vatAmount + insuranceMonthRate + insuranceVatAmount,
					invoicingPeriodType: isWeeklyPaymentChosen ? 'W' : 'M'
				};
				let data = await request('GET', 'contracts/preview-pdf', previewPDFData);

				setPreviewPDF(data);
			};

			fetchPreviewPDF();
		}
	}, [rentalData.arrivalDate, isModalOpen]);

	useEffect(() => {
		let fetchRegulaminHTML = async () => {
			let { htmlContent: agreementDataHTML } = await request('GET', 'contracts/link-parse', { link: REGULAMIN_URL });

			setRegulaminHTML(agreementDataHTML);
		};

		let fetchInsuranceConditionsHTML = async () => {
			let { htmlContent: insuranceConditionsHTML } = await request('GET', 'contracts/link-parse', { link: INSURANCE_CONDITIONS_URL });

			setInsuranceConditionsHTML(insuranceConditionsHTML);
		};

		fetchRegulaminHTML();
		fetchInsuranceConditionsHTML();
	}, [docLink, isModalOpen]);

	return (
		<Modal
			width={1100}
			title={languageSchema.BoxBooking.docsAgreement}
			visible={isModalOpen}
			onOk={handleSubmit}
			style={{ top: 20 }}
			onCancel={handleCancel}
			footer={[
				<Button key="back" onClick={handleCancel}>
					{ languageSchema.BoxBooking.back }
				</Button>,
				<Button key="submit" type="primary" disabled={!areDocsConfirmed} onClick={handleSubmit}>
					Ok
				</Button>,
			]}
		>
			<div className='frame-wrapper'>
				<div style={{
					height: 600,
					overflowY: 'scroll',
				}}>
					<div className="container" dangerouslySetInnerHTML={{ __html: previewPDF }}></div>
					<hr />
					{
						docLink && regulaminHTML &&
						<div className="container mb-4" dangerouslySetInnerHTML={{ __html: regulaminHTML }}></div>
					}
					<hr />
					{
						docLink && regulaminHTML && insuranceConditionsHTML &&
						<div className="container mb-4" dangerouslySetInnerHTML={{ __html: insuranceConditionsHTML }}></div>
					}
					<div
						className={'my-2'}
						style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'row',
						}}
					>
						<div style={{ marginRight: '7px', marginTop: '-13px' }}>
							<Checkbox
								color="#3cab26"
								size={3}
								tickAnimationDuration={100}
								backAnimationDuration={100}
								borderThickness={2}
								checked={areDocsConfirmed}
								onChange={toggleDocsAgreed}
							/>
						</div>
						<div dangerouslySetInnerHTML={{ __html: languageSchema.BoxBooking.readDocsAndAgree(REGULAMIN_URL) }} style={{ lineHeight: '1.2' }}>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default DocsAgreementModal;
