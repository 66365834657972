import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';

import { round, displayAmount } from 'helpers/amount';
import request from 'helpers/request';
import { getLanguageSchema } from 'dashboard-commons/languages';
import BoxBookingContext from 'screens/BoxBookingNew/Context';

function InsuranceSelector(props) {
	let { stockId_sm, languageSchema } = props;
	let boxBookingContext = React.useContext(BoxBookingContext);

	let [insuranceSchemes, setInsuranceSchemes] = useState([]);

	useEffect(() => {
		request('GET', `stocks/${stockId_sm}/insurance`)
			// .then(insuranceSchemes => {
			// 	return insuranceSchemes.map(scheme => {
			// 		// Maria asked for another insurance labels (but they cant configure it in SM)
			// 		let [redundantPart, neededPart] = scheme.description.split('do kwoty');
			//
			// 		return {
			// 			...scheme,
			// 			description: `${languageSchema.BoxBooking.insuranceCoverage} ${neededPart}`,
			// 		};
			// 	});
			// })
			.then(insuranceSchemes => insuranceSchemes.sort((a, b) => {
				if (Number(a.insuranceMaxValue) < Number(b.insuranceMaxValue)) {
					return -1;
				}

				return 1;
			}))
			.then(setInsuranceSchemes);
	}, []);

	let handleInsuranceSelection = insuranceItemId => {
		let insuranceScheme = insuranceSchemes.find(
			({ ledgeritemid }) =>  insuranceItemId === ledgeritemid
		);

		boxBookingContext.setSelectedInsuranceAmount(insuranceScheme.insuranceMaxValue);
		boxBookingContext.setSelectedInsuranceSchemeId(insuranceItemId);
		boxBookingContext.setInsuranceMonthRate(getCalculatedInsuranceMonthRate(insuranceScheme));
		boxBookingContext.setInsuranceWeekRate(getCalculatedInsuranceWeekRate(insuranceScheme));
	};

	let getRateWithVAT = (rate) => round(rate + (rate * boxBookingContext.bookingMeta.vatRate / 100));

	let getCalculatedInsuranceMonthRate = insuranceScheme => {
		return round(insuranceScheme.monthRate * insuranceScheme.insuranceMaxValue);
	};

	let getCalculatedInsuranceWeekRate = insuranceScheme => {
		return round(insuranceScheme.weekrate * insuranceScheme.insuranceMaxValue);
	};

	return (
		<div id="insurance_selector" className="mb-5">
			<div style={{ marginBottom: '1px', fontSize: '20px' }}>
				{languageSchema.BoxBooking.chooseYourInsurance} ({languageSchema.BoxBooking.valueOfGoods})
			</div>
			<Select
				onChange={handleInsuranceSelection}
				style={{
					width: '100%',
					border: boxBookingContext.bookingMeta.isInsuranceNotChosenError ?
						'1px solid #F93822' :
						'none'
				}}
				placeholder={languageSchema.BoxBooking.valueOfGoods}
				dropdownStyle={{ zIndex: 100000 }}
			>
				{insuranceSchemes.map(e => (
					<Select.Option key={e.ledgeritemid} value={e.ledgeritemid}>
						{e.description}
					</Select.Option>
				))}
			</Select>

			{
				boxBookingContext.bookingMeta.insuranceMonthRate ?
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{props.languageSchema.BoxBooking.price}:
						<span style={{ fontSize: '18px', marginLeft: '7px' }}>
							{
								boxBookingContext.bookingMeta.isWeeklyPaymentChosen ?
									<b>
										{displayAmount(getRateWithVAT(boxBookingContext.bookingMeta.insuranceWeekRate), boxBookingContext.bookingMeta.selectedStock.currency)}
										{'   '}/ {props.languageSchema.BoxBooking.week}
									</b> :
									<b>
										{displayAmount(getRateWithVAT(boxBookingContext.bookingMeta.insuranceMonthRate), boxBookingContext.bookingMeta.selectedStock.currency)}
										{'   '}/ {props.languageSchema.BoxBooking.month}
									</b>
							}
						</span>
					</div> :
					null
			}
		</div>
	);
}

let mapStateToProps = state => ({
	language: state.common.language,
	languageSchema: getLanguageSchema(state.common.language),
});

export default connect(mapStateToProps)(InsuranceSelector);
