import React, { useState } from 'react';
import { round } from 'helpers/amount';

import BoxBookingContext from './index.js';

import { DEF_BLUE_MEDIA_PROCESSOR } from 'utils/constants';

import { scrollToDiv } from 'helpers/common.js';

const VAT_RATE_PERCENT = 23;
const INVOICING_PERIOD_TYPES_MAP = {
	month: 'M',
	week: 'W',
};

export default function BoxBookingContextProvider({ children }) {
	let [selectedStockId, setStockId] = useState(null);
	let [selectedStock, setStock] = useState(null);
	let [selectedSizeCode, setSizeCode] = useState(null);
	let [selectedSizeGroup, setSizeGroup] = useState(null);
	let [selectedBox, setSelectedBox] = useState(null);
	let [pureMonthRate, setPureRate] = useState(null);
	let [pureWeekRate, setPureWeekRate] = useState(null);
	let [arrivalDate, setArrivalDateValue] = useState(null);
	let [departureDate, setDepartureDateValue] = useState(null);
	let [selectedContractDuration, setContractDuration] = useState(null);
	let [selectedInsuranceSchemeId, setSelectedInsuranceSchemeIdValue] = useState(null);
	let [insuranceMonthRate, setInsuranceMonthRate] = useState(null);
	let [insuranceWeekRate, setInsuranceWeekRate] = useState(null);
	let [discountPercent, setDiscountPercent] = useState(null);
	let [vatRate, setVatRate] = useState(VAT_RATE_PERCENT);
	let [areDocsConfirmed, setDocsConfirmed] = useState(false);
	let [contactSource, setСontactSource] = useState(null);
	let [reasonForStorage, setReasonForStorage] = useState(null);
	let [user, setUser] = useState(null);
	let [isSidebarOpened, setSideBarOpened] = useState(false);
	let [paymentMethod, togglePaymentMethod] = useState(DEF_BLUE_MEDIA_PROCESSOR); // for now we only support processing via Blue Media
	let [isArrivalDateNotChosenError, setArrivalDateError] = useState(false);
	let [invoicingPeriodType, setInvoicingPeriodType] = useState('M');
	let [isInsuranceNotChosenError, setInsuranceError] = useState(false);
	let [selectedInsuranceAmount, setSelectedInsuranceAmount] = useState(null);
	let [tariff, setTariff] = useState(null);

	let isWeeklyPaymentChosen = invoicingPeriodType === 'W';

	// let isOneEuroForFirstMonthActionApplied =
	// 	1 < selectedContractDuration;

	let amountForInitialPeriod = invoicingPeriodType === INVOICING_PERIOD_TYPES_MAP.week ? pureWeekRate : pureMonthRate;
	let insuranceAmount = invoicingPeriodType === INVOICING_PERIOD_TYPES_MAP.week ? insuranceWeekRate : insuranceMonthRate;
	let insuranceVatAmount = round(insuranceAmount * vatRate) / 100;

	let discountAmount = round(amountForInitialPeriod * discountPercent / 100);
	//
	// if (isOneEuroForFirstMonthActionApplied) {
	// 	let oneEuroMonthRate = 1;
	//
	// 	amountForInitialPeriod = amountForInitialPeriod + oneEuroMonthRate;
	// 	insuranceAmount = insuranceAmount * 2; // for 2 months
	// }

	let getDepositAmount = () => {
		return isWeeklyPaymentChosen ? pureWeekRate : pureMonthRate;
	};

	amountForInitialPeriod = round(amountForInitialPeriod);

	let vatAmount = round((amountForInitialPeriod) * vatRate / 100);

	// amount + VAT + deposit
	let totalToPay = round(amountForInitialPeriod + vatAmount + getDepositAmount() + insuranceAmount + insuranceVatAmount);

	let setArrivalDate = (value) => {
		setArrivalDateValue(value);
		setArrivalDateError(false);
	};

	let setDepartureDate = (value) => {
		setDepartureDateValue(value);
		// setDepartureDateError(false);
	};

	let handleArrivalDateNotChosen = () => {
		setArrivalDateError(true);
		scrollToDiv('#arrival_date');
	};

	let setSelectedInsuranceSchemeId = (value) => {
		setSelectedInsuranceSchemeIdValue(value);
		setInsuranceError(false);
	};

	let handleInsuranceNotChosen = () => {
		setInsuranceError(true);
		scrollToDiv('#insurance_selector');
	};

	return (
		<BoxBookingContext.Provider
			value={{
				bookingMeta: {
					selectedStockId,
					selectedSizeCode,
					selectedSizeGroup,
					selectedBox,
					pureMonthRate,
					pureWeekRate,
					arrivalDate,
					departureDate,
					selectedContractDuration,
					selectedInsuranceSchemeId,
					insuranceMonthRate,
					insuranceWeekRate,
					insuranceAmount,
					discountAmount,
					discountPercent,
					vatRate,
					vatAmount,
					insuranceVatAmount,
					totalToPay,
					areDocsConfirmed,
					selectedStock,
					contactSource,
					reasonForStorage,
					user,
					isSidebarOpened,
					paymentMethod,
					isArrivalDateNotChosenError,
					isInsuranceNotChosenError,
					selectedInsuranceAmount,
					invoicingPeriodType,
					isWeeklyPaymentChosen,
					tariff
				},
				setStockId,
				setStock,
				setSizeCode,
				setSizeGroup,
				setSelectedBox,
				setSelectedInsuranceAmount,
				setInvoicingPeriodType,
				setPureRate,
				setPureWeekRate,
				setArrivalDate,
				setDepartureDate,
				setContractDuration,
				setSelectedInsuranceSchemeId,
				setInsuranceMonthRate,
				setInsuranceWeekRate,
				setDiscountPercent,
				setVatRate,
				setDocsConfirmed,
				setСontactSource,
				setReasonForStorage,
				setUser,
				setSideBarOpened,
				togglePaymentMethod,
				handleArrivalDateNotChosen,
				handleInsuranceNotChosen,
				setTariff
			}}
		>
			{children}
		</BoxBookingContext.Provider>
	);
}
